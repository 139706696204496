import { render, staticRenderFns } from "./ProductDescriptionComponent.vue?vue&type=template&id=ca97664a&"
import script from "./ProductDescriptionComponent.vue?vue&type=script&lang=js&"
export * from "./ProductDescriptionComponent.vue?vue&type=script&lang=js&"
import style0 from "./ProductDescriptionComponent.vue?vue&type=style&index=0&id=ca97664a&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="product-description">
    <ProductGalleryComponent
      :items="data.img_card"
      :head_img="data.img"
      class="product-description__gallery"
    />
    <div class="product-description__text">
      <div class="product-description__text-header">
        <!--        <h2>{{ data.title }}</h2>-->
        <span>Артикул: {{ data.articul }}</span>
      </div>
      <div class="product-description__text-main">
        <div class="product-description__text-prop">
          <!--          <div class="product-description__text-prop_item">-->
          <!--            <span>Состав</span>-->
          <!--            <span class="product-description__text-prop_item-line"></span>-->
          <!--            <span>Мандарины</span>-->
          <!--          </div>-->
          <div v-if="data.weight" class="product-description__text-prop_item">
            <span>Вес (брутто)</span>
            <span class="product-description__text-prop_item-line"></span>
            <span>{{ data.weight }} г.</span>
          </div>
          <div
            v-for="(item, i) in data.attribute_values"
            :key="i"
            class="product-description__text-prop_item"
          >
            <span>{{ item.attribute?.title }}</span>
            <span class="product-description__text-prop_item-line"></span>
            <span>{{ item.value.value }}</span>
          </div>
          <div class="product-description__text-prop_item">
            <span>Тип продукта</span>
            <span class="product-description__text-prop_item-line"></span>
            <span>{{ data.category?.title }}</span>
          </div>
          <router-link :to="{ name: 'product', params: { id: data.id }, hash: '#about' }">
            О товаре
          </router-link>
        </div>
        <div class="product-description__text-price">
          <p>Цена</p>
          <div class="product-description__text-price_indicator">
            <span>
              {{ data.price | share_price(data) | formatPrice }}
            </span>
            <span v-if="data.share && data.share.percent">
              {{ data.price | formatPrice }}
            </span>
          </div>
          <ProductCountChangeComponent :data="data" is-product-page />
          <span v-if="isCount" class="product-description__text-price_stock">Есть в наличии</span>
          <span
            v-if="!isCount"
            class="product-description__text-price_stock product-description__text-price_stock--red"
          >
            Нет в наличии
          </span>
        </div>
      </div>
      <ShareNetworksComponent :data="data" />
    </div>
  </div>
</template>

<script>
import ProductGalleryComponent from "components/product/ProductGalleryComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
import ShareNetworksComponent from "components/ShareNetworksComponent.vue";

export default {
  name: "ProductDescriptionComponent",
  props: {
    data: Object,
  },
  computed: {
    isCount() {
      return !!this.$store.state.product_page.count;
    },
  },
  components: { ShareNetworksComponent, ProductCountChangeComponent, ProductGalleryComponent },
};
</script>

<style lang="stylus">
.product-description {
  //display flex
  gap 30px
  display grid
  grid-template-columns 524fr 746fr
  +below(1024px) {
    grid-template-columns 1fr
  }

  &__text {
    display flex
    flex-direction column
    gap 30px
  }

  &__text-prop {
    display flex
    gap 15px
    width 100%
    flex-direction column;

    &_item {
      display grid
      grid-template-columns auto 1fr auto
      grid-gap 15px
      align-items baseline
      &-line {
        height 1px
        border: 0.5px dashed var(--dark-light);
        min-width 30px
      }

      span {
        font-weight: 400;
        font-size: 1em;
        line-height: 26px;
        color: var(--dark);

        &:last-child {
          text-align right
        }
      }
    }
  }

  &__text-header {
    display flex
    width: 100%;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: var(--dark-light);
      margin-left auto
    }
  }

  &__text-main {
    display: grid;
    justify-content: space-between;
    gap: 30px;
    grid-template-columns: 302fr 413fr;
    +below(1024px){
      display flex
      flex-direction column
    }

    a {
      font-weight: 400;
      font-size: 1em;
      line-height: 26px;
      text-decoration-line: underline;
      padding-top 20px
      color: var(--main);
    }
  }

  &__text-price {
    display flex
    flex-direction column
    gap 26px
    padding 20px
    width 100%
    background: var(--gray);
    border: 1px solid var(--gray-dark);
    border-radius: var(--big-radius);
    height: fit-content;

    &_indicator {
      display flex
      align-items: center;
      gap 10px

      :nth-child(1) {
        font-weight: 700;
        font-size: 2em;
        line-height: 38px;
        color: var(--red);
      }

      :nth-child(2) {
        font-size: 1.25em;
        line-height: 28px;
        text-decoration-line: line-through;
        color: var(--dark-light);
      }
    }

    &_stock {
      font-weight: 400;
      font-size: 1em;
      line-height: 26px;
      color: var(--green-dark);

      &--red {
        color var(--red-2)
      }
    }

    p {
      margin-bottom: -20px
      font-size .875rem
      line-height 20px
      color var(--dark-light)
    }
  }

  &__text-buttons {
    display flex
    gap 15px
    align-items: center;
    justify-content: flex-end;
    margin-top: -15px;

    span {
      color: var(--dark-light);
    }

    &_socials {
      display flex
      gap 5px

      .icon {
        width: 16px
        height: 16px
      }
    }
  }
}
</style>

<template>
  <div class="product-composition">
    <div class="product-composition_left">
      <h2>Состав и характеристики</h2>
      <div class="product-composition_left__indicators">
        <div
          v-for="(item, i) in data.attribute_values"
          :key="i"
          class="product-composition_left__indicators-item"
        >
          <span>{{ item.attribute.title }}</span>
          <span class="product-composition_left__indicators-item_line"></span>
          <span>{{ item.value.value }}</span>
        </div>
      </div>
    </div>
    <div class="product-composition_right">
      <h2>Описание</h2>
      <span>{{ data.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCompositionComponent",
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style lang="stylus">
.product-composition{
  display grid
  grid-template-columns 524fr 744fr
  gap 30px
  +below(1024px){
    grid-template-columns 1fr
  }

  &_left{
    display flex
    flex-direction column
  }

  &_left__indicators{
    display flex
    flex-direction column
    gap 15px

    &-item{
      display grid
      grid-template-columns auto 1fr auto
      grid-gap 5px
      align-items baseline
      &_line {
        height 1px
        border: 0.5px dashed var(--dark-light);
      }
      span{
        font-weight: 400;
        font-size: 1em;
        line-height: 26px;
        color: var(--dark);
      }
    }
  }

  &_right{
    display flex
    flex-direction column

    pre {
      font-weight: 400;
      font-size: 1em
      line-height: 26px;
      color: var(--dark);
      white-space pre-line
    }
  }
}
</style>

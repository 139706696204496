<template>
  <div
    class="img-zoom"
    :class="{ 'img-zoom--mobile': isMobile }"
    :style="{
      backgroundImage: `url(\'${
        src || $options.filters.image_by_id(head_img, $store.state, { width, height, quality })
      }\')`,
    }"
    @mousemove="zoom"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ImgZoomComponent",
  props: {
    // добавлены параметры как у изображения, т.к. приближаемое фото может быть более высокого качества
    // добавить loading для загрузки фонового изображения
    src: String,
    head_img: Object,
    quality: {
      type: String,
      default: "99",
    },
    width: [Number, String],
    height: [Number, String],
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 1025;
  },
  methods: {
    zoom(e) {
      if (!this.isMobile) {
        let target = e.currentTarget;
        let offsetX = e.offsetX;
        let offsetY = e.offsetY;
        let x = (offsetX / target.offsetWidth) * 100;
        let y = (offsetY / target.offsetHeight) * 100;
        target.style.backgroundPosition = x + "% " + y + "%";
      }
    },
  },
};
</script>

<style lang="stylus">
.img-zoom {
  background-position: 50% 50%;
  position: relative;
  width: 100%;
  max-width 1000px
  height 100%
  overflow: hidden;
  cursor: pointer;
  background-repeat no-repeat
  background-size 180%
  display flex
  align-items center
  justify-content center

  &:not(:hover) {
    background-image none !important
  }

  &:not(&--mobile):hover {
    img {
      opacity: 0;
    }
  }

  &--mobile {
    background-image none !important
  }

  img {
    display: block;
    transition: opacity .5s;
    width 100%
    height 100%
  }
}
</style>

<template>
  <main class="page product-page">
    <BreadcrumbsComponent :links="links" :title="product?.title" />
    <div class="page__inner product-page__inner container-padding">
      <ProductDescriptionComponent :data="product" />
      <ProductSliderComponent :data="product.often_buy" />
      <SliderComponent
        v-if="shares"
        :items="shares"
        v-slot="{ item }"
        :slider-options="shareSliderOptions"
        title="Участвует в акциях"
        class="wrapper"
        shadow
      >
        <ShareCardComponent :share="item" />
      </SliderComponent>
      <ProductCompositionComponent id="about" :data="product" />
    </div>
  </main>
</template>

<script>
import ProductSliderComponent from "./components/ProductSliderComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProductDescriptionComponent from "./components/ProductDescriptionComponent.vue";
import ProductCompositionComponent from "./components/ProductCompositionComponent.vue";
import ShareCardComponent from "components/ShareCardComponent.vue";
import SliderComponent from "components/slider/SliderComponent.vue";
import GET from "@/graphql/pages/ProductPage.graphql";
import COUNT from "@/graphql/queries/count.graphql";

let integration_id = "";
export default {
  name: "ProductPage",
  components: {
    SliderComponent,
    ShareCardComponent,
    ProductCompositionComponent,
    ProductDescriptionComponent,
    BreadcrumbsComponent,
    ProductSliderComponent,
  },
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: GET,
        variables: {
          id: parseInt(route.params.id),
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        store.state.product_page.product_info = data.products_item;
        integration_id = data.products_item.integration_id;
        store.state.product_page.shares = data.shares;
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});

    // получение количества товаров на 1с
    await apollo.defaultClient
      .query({
        query: COUNT,
        variables: {
          integration_id: integration_id,
        },
      })
      .then(({ data }) => {
        store.state.product_page.count = data.check_for_availability.count;
      })
      .catch(() => {});
  },
  // beforeRouteUpdate() {
  //   this.$store.state.product_page.product_info = {};
  // },
  data() {
    return {
      shareSliderOptions: {
        slidesPerView: 1.3,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        preloadImages: true,
        breakpoints: {
          440: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          680: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1050: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  computed: {
    product() {
      return this.$store.state.product_page.product_info;
    },
    shares() {
      return this.$store.state.product_page.shares;
    },
    links() {
      let links = [];
      if (this.product.category) {
        let category = this.product.category;
        while (category) {
          links.unshift({
            title: category.title,
            route: {
              name: "catalog",
              params: { id: category.id },
            },
          });
          category = category.parent;
        }
      }
      return links;
    },
  },
  methods: {
    // getCount() {
    //   console.log(this.product.integration_id);
    //   this.$apollo.provider.defaultClient
    //     .query({
    //       query: COUNT,
    //       variables: {
    //         integration_id: this.product.integration_id,
    //       },
    //     })
    //     .then(({ data }) => {
    //       this.$store.state.product_page.product_info.count = data.check_for_availability.count;
    //     })
    //     .catch(() => {});
    // },
    item_img(data) {
      let image = this.$options.filters.item_img(data);
      return image ? image.path : null;
    },
  },
  metaInfo() {
    return {
      title: this.product.meta_title || this.product.title,
      description: this.product.meta_description,
      keywords: this.product.meta_keywords,
      meta: [
        {
          vmid: "og:image",
          name: "og:image",
          content: this.product.head_img
            ? this.$store.state._env.MEDIA_ENDPOINT + this.item_img(this.product)
            : null,
        },
      ],
    };
  },
};
</script>

<style lang="stylus">

.product-page {
  &__inner {
    display flex
    flex-direction column
    gap 50px
  }
}
</style>

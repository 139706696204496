<template>
  <div
    class="product-gallery"
    :class="{ 'product-gallery--controls': items && items.length && items.length > 1 }"
  >
    <ProductParamSlider
      v-if="items && items.length"
      v-slot="{ item }"
      :items="items"
      :slider-options="sliderOptions"
      ref="params"
      @prev="prev"
      @next="next"
    >
      <label
        class="product-gallery__slider-img"
        :class="{ 'product-gallery__slider-img--active': active === item.id }"
      >
        <input type="radio" v-show="false" :value="item.id" v-model="active" />
        <ImgComponent :img="item" width="400" height="400" />
      </label>
    </ProductParamSlider>
    <div class="product-gallery__image">
      <template v-if="isImagesModal">
        <button @click="prev" class="product-gallery__btn product-gallery__btn-prev btn">
          <IconComponent name="arrow-left-1" />
        </button>
        <button @click="next" class="product-gallery__btn product-gallery__btn-next btn">
          <IconComponent name="arrow-right-1" />
        </button>
      </template>
      <div class="product-gallery__top">
        <div class="product-gallery__top-tags">
          <!--          <span class="product-gallery__top-tags_item product-gallery__top-tags_item&#45;&#45;sale"-->
          <!--            >-{{ data.share.percent }}%</span-->
          <!--          >-->
          <span class="product-gallery__top-tags_item product-gallery__top-tags_item--discount">-40%</span>
          <span class="product-gallery__top-tags_item product-gallery__top-tags_item--new">Новинка</span>
        </div>
        <button
          :class="{ 'product-gallery__heart': inFavorites }"
          type="button"
          class="btn btn--white-hollow"
          :title="inFavorites ? 'Убрать из избранного' : 'В избранное'"
          @click.prevent="addToFavorites"
        >
          <IconComponent name="heart" />
        </button>
      </div>
      <ImgZoomComponent :head_img="activeItem" width="900" height="900">
        <div @click="showImagesModal" class="product-gallery__image-ratio">
          <div class="ratio-container">
            <ImgComponent :img="activeItem" width="900" height="900" :default-alt="defaultAlt" contain />
          </div>
        </div>
      </ImgZoomComponent>
    </div>
  </div>
</template>

<script>
import ProductParamSlider from "components/product/ProductParamSlider.vue";
import ImgZoomComponent from "components/ImgZoomComponent.vue";
import IconComponent from "components/IconComponent.vue";
import ImagesGalleryModal from "components/modals/components/ImagesGalleryModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";

export default {
  name: "ProductGalleryComponent",
  props: {
    defaultAlt: String,
    items: {
      type: Array,
      default: () => [],
    },
    head_img: Object,
    firstActiveItem: Object,
    isModal: Boolean, // модалка с предварительным просмотром товара
    isImagesModal: Boolean, // модалка для просмотра фотографий товара
  },
  data() {
    return {
      active: null,
      sliderOptions: {
        direction: "horizontal",
        slidesPerView: "auto",
        spaceBetween: 10,
        pagination: false,
        clickable: false,
        breakpoints: {
          721: {
            direction: "vertical",
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    inFavorites() {
      return this.$store.state.auth.favorites_ids.includes(parseInt(this.$route.params.id));
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
    activeItem() {
      return this.items.find((i) => i.id === this.active) || this.head_img;
    },
  },
  created() {
    if (this.items && this.items.length) {
      this.active = this.items[0].id;
    }
  },
  mounted() {
    if (this.isImagesModal) {
      document.addEventListener("keydown", this.handleKeyDown);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", () => {});
  },
  watch: {
    items() {
      if (this.items && this.items.length) {
        this.active = this.items[0].id;
      }
    },
  },
  methods: {
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites_ids.indexOf(parseInt(this.$route.params.id));
        if (index !== -1) {
          this.$store.state.auth.favorites_ids.splice(index, 1);
        } else {
          this.$store.state.auth.favorites_ids.push(parseInt(this.$route.params.id));
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: parseInt(this.$route.params.id),
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
    handleKeyDown(e) {
      if ([37, 38].includes(e.keyCode)) {
        this.prev();
      }
      if ([39, 40].includes(e.keyCode)) {
        this.next();
      }
    },
    next() {
      let currentIndex = this.items.findIndex((item) => item.id === this.active);
      // если есть текущий элемент и он не последний
      if (![-1, this.items.length - 1].includes(currentIndex) && this.$refs.params) {
        this.active = this.items[currentIndex + 1]?.id;
        this.$refs.params.slideNext();
      }
    },
    prev() {
      let currentIndex = this.items.findIndex((item) => item.id === this.active);
      // если есть текущий элемент и он не первый
      if (![-1, 0].includes(currentIndex) && this.$refs.params) {
        this.active = this.items[currentIndex - 1]?.id;
        this.$refs.params.slidePrev();
      }
    },
    showImagesModal() {
      if (!this.isModal && !this.isImagesModal) {
        this.$store.state._modals.push({
          component: ImagesGalleryModal,
          options: {
            images: this.items,
            head_img: this.head_img,
            defaultAlt: this.defaultAlt,
            activeItem: this.activeItem,
          },
          fullHeight: true,
        });
      }
    },
  },
  components: { IconComponent, ImgZoomComponent, ProductParamSlider },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.product-gallery {
  display flex
  align-items flex-start
  gap 15px
  +below(720px) {
    gap 0
    flex-direction column-reverse
  }

  param_size = 80px

  .param-slider {
    width param_size
    height 427px
    flex-shrink: 0;
    padding 83px 0
    +below(720px) {
      width 100%
      height param_size
      padding 0 55px
    }
  }

  &__slider-img {
    display flex
    align-items center;
    justify-content center
    border: 1px solid var(--gray-dark);
    border-radius: var(--main-radius)
    width param_size
    height param_size
    overflow hidden
    cursor pointer
    transition border-color var(--transition)

    &:not(&--active):hover {
      border-color var(--green-dark)
    }

    &--active {
      border-color var(--main);
    }

    img {
      max-width 100%
      max-height 100%
      object-fit contain
      object-position center
    }
  }

  &__image {
    height auto
    width 100%
    max-width 100%
    display flex
    justify-content center
    align-items center
    overflow hidden
    border: 1px solid var(--gray-dark);
    border-radius: 30px;
    position relative

    &-ratio {
      ratio(1, 1, contain)

      img {
        padding 50px
      }
    }
  }

  &__top {
    display flex
    justify-content space-between
    position absolute
    top 14px
    z-index 9
    width 100%
    padding 0 15px

    &-tags{
      display flex
      gap 5px
      &_item {
        font-size: 0.75em
        line-height: 14px;
        padding 5px
        border-radius: 100px;
        color var(--white)
        height: fit-content;

        &--new {
          background var(--green)
        }

        &--discount {
          background var(--yellow)
          color var(--dark)
        }

        &--hit {
          background var(--red)
        }

        &--sell {
          background var(--red)
        }
      }
    }

    .btn{
      border none
      width: 35px;
      height: 35px;
      .icon svg{
        max-width: 20px;
        max-height 20px
      }
    }
  }

  &__heart {
    .icon svg path {
      fill var(--main)
    }
  }
}
</style>

<template>
  <div
    class="param-slider"
    :class="{ 'param-slider--arrows': canShowArrows, 'param-slider--vertical': vertical }"
    v-if="items && items.length"
  >
    <button
      type="button"
      :title="vertical ? 'Вверх' : 'Влево'"
      class="param-slider__arrow"
      @click="$emit('prev')"
      :class="{ 'param-slider__arrow--disabled': swiper && swiper.isBeginning }"
    >
      <IconComponent v-if="vertical" name="arrow_up-1" />
      <IconComponent v-else name="arrow_left-1" />
    </button>
    <div class="param-slider__items swiper" ref="items">
      <div class="swiper-wrapper">
        <div @click="moveToSelectedSlide" class="swiper-slide" v-for="(item, i) in items" :key="i">
          <slot :item="item"></slot>
        </div>
      </div>
    </div>
    <button
      type="button"
      :title="vertical ? 'Вниз' : 'Вправо'"
      class="param-slider__arrow"
      @click="$emit('next')"
      :class="{ 'param-slider__arrow--disabled': swiper && swiper.isEnd }"
    >
      <IconComponent v-if="vertical" name="arrow_down-1" />
      <IconComponent v-else name="arrow_right-1" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ProductParamSlider",
  props: {
    items: Array,
    sliderOptions: Object,
  },
  data() {
    return {
      direction: null,
      slidesPerView: null,
      swiper: null,
    };
  },
  computed: {
    canShowArrows() {
      return this.items.length > (this.slidesPerView || 0);
    },
    vertical() {
      return (this.direction || "horizontal") === "vertical";
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
      this.swiper.on("breakpoint", (swiper, breakpointParams) => {
        this.updateLocalOptions(breakpointParams);
      });
      this.swiper.emit("breakpoint", this.getCurrentBreakpoint());
    });
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    moveToSelectedSlide() {
      if (this.swiper) {
        this.swiper.slideTo(this.swiper.clickedIndex);
      }
    },
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints) {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
    updateLocalOptions(options) {
      this.direction = options.direction;
      this.slidesPerView = options.slidesPerView;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
@import "~@/styles/mixins/svg.styl"
.swiper-slide {
  width auto
  height: auto;
  display inline-flex
  align-items center
  justify-content center
}

.param-slider {
  width: 100%;
  display flex
  justify-content flex-start
  align-items flex-start
  position relative
  +below(1024px){
    margin-top 15px
  }

  &--arrows:not(&--vertical) {
    padding 0 50px
  }

  //&--arrows&--vertical {
  //  padding 50px 0
  //}

  &--vertical &__arrow {
    &:first-child {
      absolute left top right
      bottom unset
    }

    &:last-child {
      absolute right left bottom
      top unset
    }
  }

  &__arrow {
    margin auto
    box-sizing: border-box;
    width 40px
    height 40px
    display flex
    align-items center
    justify-content center
    cursor pointer
    background none
    padding 0
    opacity 0.3
    background: var(--gray);
    border 1px solid var(--gray-dark)
    border-radius 50%
    z-index 900
    transition var(--transition)

    &:not(&--disabled):hover {
      border-color var(--main)
      background var(--white)
      opacity: 1;

      .icon svg {
        svg(var(--main))
      }
    }

    .icon {
      width 10px
      height 4px

      svg path {
        stroke var(--dark)
        transition var(--transition)
      }
    }

    &:first-child {
      absolute left top bottom
    }

    &:last-child {
      absolute right top bottom
    }
  }

  &__items {
    width 100%
    height 100%

    .swiper-slide {
      height auto !important
    }
  }
}
</style>

<template>
  <div v-if="data && data.length" class="product-slider wrapper">
    <SliderComponent
      :items="data"
      v-slot="{ item }"
      :slider-options="productSliderOptions"
      title="С этим часто берут"
      shadow
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
  </div>
</template>

<script>
import SliderComponent from "components/slider/SliderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";

export default {
  name: "ProductSliderComponent",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      productSliderOptions: {
        slidesPerView: 1.2,
        spaceBetween: 30,
        pagination: false,
        clickable: false,
        preloadImages: true,
        watchOverflow: true,
        breakpoints: {
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          580: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  components: { ProductCardComponent, SliderComponent },
};
</script>
